import { render, staticRenderFns } from "./routuanOrder.vue?vue&type=template&id=146dba94&scoped=true"
import script from "./routuanOrder.vue?vue&type=script&lang=js"
export * from "./routuanOrder.vue?vue&type=script&lang=js"
import style0 from "./routuanOrder.vue?vue&type=style&index=0&id=146dba94&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "146dba94",
  null
  
)

export default component.exports